/* es-lint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FunctionComponent, useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';

import {
	Box,
	Button,
	Checkbox,
	Container,
	createStyles,
	FormControl,
	FormControlLabel,
	FormHelperText,
	LinearProgress,
	Theme,
	Typography
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import { Footer } from 'features/footer';

import {
	selectIsAccountLoading,
	selectIsUserAuthorized,
	selectUserDataAccessStatus,
	selectUserInfo
} from '../../../auth/selectors';
import { LoaderButton } from '../../../components';
import { UserDataAccessStatuses } from '../../../auth/types';

import { requestAccessSlice } from '../slices';
import { UiFormFields } from '../types';
import {
	selectConfiguration,
	selectIsConfigurationInitialized,
	selectIsRequestLoading,
	selectIsRequestSent
} from '../selectors';

import { OmicCard } from './OmicCard';
import { OmicCardsInfo } from './OmicCardsInfo';
import { DuaFormFields } from './DuaFormFields';
import { FlowDiagram } from './FlowDiagram';
import { CollaboratorsFields } from './CollaboratorsFields';
import { useDebouncedValidate } from 'hooks';
import { Collaborator } from '../types/UiFormFields';
import { getDuaValidationSchema } from '../utils';
import { BusinessOfficialFields } from './BusinessOfficialFields';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			padding: theme.spacing(3),
			maxWidth: '1480px'
		},
		backButton: {
			color: theme.palette.primary.main,
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			marginBottom: theme.spacing(6)
		},
		infoBlock: {
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '1rem',
			lineHeight: '1.5rem',
			letterSpacing: '0.009375rem',
			color: theme.palette.grey['800'],

			'& h2': {
				fontStyle: 'normal',
				fontWeight: 'normal',
				fontSize: '1.5rem',
				lineHeight: '1.5rem',
				letterSpacing: '0.01125rem',
				color: theme.palette.common.black,
				margin: theme.spacing(0, 0, 2, 0)
			},
			'& p, & li': {
				margin: theme.spacing(2, 0)
			},
			'& ul, & ol': {
				// eslint-disable-next-line @typescript-eslint/no-magic-numbers
				padding: theme.spacing(0, 0, 0, 2.25)
			},
			'& a': {
				color: theme.palette.primary.main
			}
		},
		textField: {
			minHeight: '75px'
		},
		agreementControlRoot: {
			alignItems: 'center'
		},
		agreementControlLabel: {
			marginTop: '2px'
		},
		fileFormControlRoot: {
			margin: 0
		},
		listItemDense: {
			padding: 0
		},
		listItemIconRoot: {
			minWidth: 0,
			paddingRight: theme.spacing(1)
		},
		codeOfConductAgreementControlRoot: {
			alignItems: 'center'
		},
		codeOfConductAgreementControlLabel: {
			marginTop: '2px'
		},
		submitGroup: {
			alignItems: 'center'
		},
		submitButtonWrapper: {
			width: '275px'
		},
		validationSummary: {
			color: '#B00020',
			fontSize: '0.85rem'
		}
	})
);

export const RequestAccess: FunctionComponent = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const isAccountLoading = useSelector(selectIsAccountLoading);
	const isUserAuthorized = useSelector(selectIsUserAuthorized);
	const { userName, userEmails } = useSelector(selectUserInfo);
	const isConfigurationInitialized = useSelector(selectIsConfigurationInitialized);
	const pageConfiguration = useSelector(selectConfiguration);
	const isRequestLoading = useSelector(selectIsRequestLoading);
	const isRequestSent = useSelector(selectIsRequestSent);

	const [isFormInitialized, setIsFormInitialized] = useState(false);

	const isPageInitialized = !isAccountLoading && isConfigurationInitialized;
	const userAccessStatus = useSelector(selectUserDataAccessStatus);

	const validationSchema = useMemo(
		() => getDuaValidationSchema(pageConfiguration),
		[pageConfiguration]);

	const form = useFormik<UiFormFields>({
		validateOnChange: false,
		initialValues: {
			confirmCodeOfConductAgreement: false,
			firstName: '',
			lastName: '',
			workingEmail: '',
			credentials: '',
			roleOnProject: '',
			dataUseProposal: '',
			institution: '',
			country: '',
			projectTimeframe: '',
			accessToGenomicsLevel1: false,
			accessToGenomicsLevel2: false,
			accessToGenomicsLevel3: false,
			accessToGenomicsLevel4: false,
			accessToEpigenomicsLevel1: false,
			accessToEpigenomicsLevel2: false,
			accessToEpigenomicsLevel3: false,
			accessToEpigenomicsLevel4: false,
			accessToTranscriptomicsLevel1: false,
			accessToTranscriptomicsLevel2: false,
			accessToTranscriptomicsLevel3: false,
			accessToTranscriptomicsLevel4: false,
			accessToProteomicsLevel1: false,
			accessToProteomicsLevel2: false,
			accessToProteomicsLevel4: false,
			confirmAgreements: false,
			businessOfficialName: '',
			businessOfficialEmail: '',
			businessOfficialTitle: '',
			collaborators: [
				{
					name: '',
					title: '',
					roleOnProject: '',
					email: ''
				}
			]
		},
		validationSchema,
		onSubmit: (values: UiFormFields) => {
			dispatch(requestAccessSlice.actions.sendFormDataRequest(values));
		},
	});

	const onOmicCardLevelClick = useCallback((formFieldName: string) => {
		void form.setFieldValue(formFieldName, !form.values[formFieldName as keyof UiFormFields]);
	},
	[form]);

	const isValidationSummaryVisible = useMemo(() => {
		const errorValues = Object.entries(form.errors).flatMap(m => {
			return m[0] === 'collaborators'
				? Object.values(m[1]).flatMap((collab: Collaborator) => Object.values(collab))
				: m[1];
		});

		return Object.entries(form.touched).some(s => s[1] === true) &&
			errorValues.length > 0;
	},
	[form.touched, form.errors]);

	useEffect(() => {
		dispatch(requestAccessSlice.actions.pageConfigurationRequest());
	}, [dispatch]);

	useEffect(() => {
		if (isFormInitialized) {
			return;
		}

		if (!isAccountLoading && isUserAuthorized) {
			setIsFormInitialized(true);

			void form.setFieldValue('userName', userName);
			void form.setFieldValue('workingEmail', userEmails[0] || '');
		}
	}, [form, isAccountLoading, isUserAuthorized, userName, userEmails, isFormInitialized]);

	useDebouncedValidate({
		validate: async values => {
			await form.validateForm(values);
		},
		debounceTime: 500,
		values: form.values,
	});

	return (
		<Box bgcolor="#ffffff" position="relative">
			<Box
				visibility={!isPageInitialized ? 'visible' : 'hidden'}
				position="absolute"
				top={0}
				width="100%"
				zIndex={zIndex.appBar}
			>
				<LinearProgress />
			</Box>
			{isPageInitialized &&
				<>
					<Container className={classes.container}>
						<FormikProvider value={form}>
							<Button
								classes={{ root: classes.backButton }}
								href={pageConfiguration.markup.backButton.link}
								variant='outlined'
								startIcon={<KeyboardArrowLeftIcon />}>
								{pageConfiguration.markup.backButton.text}
							</Button>
							<Typography component="h1" variant="h3">
								{pageConfiguration.markup.pageHeader}
							</Typography>
							<Box pb={5} />
							<Box display="flex" justifyContent="space-between" flexWrap="wrap-reverse">
								<Box
									className={classes.infoBlock}
									style={{ flex: '0 1 690px' }}>
									<Box dangerouslySetInnerHTML={{ __html: pageConfiguration.markup.leftInfoSectionAccessHtml }} />
									{
										userAccessStatus === UserDataAccessStatuses.NoAccess &&
										<FormControl error={form.touched.confirmCodeOfConductAgreement && Boolean(form.errors.confirmCodeOfConductAgreement)}>
											<FormControlLabel
												classes={{ root: classes.codeOfConductAgreementControlRoot, label: classes.codeOfConductAgreementControlLabel }}
												control={
													<Checkbox
														name="confirmCodeOfConductAgreement"
														checked={form.values.confirmCodeOfConductAgreement}
														onChange={form.handleChange}
														color="primary"
													/>
												}
												label={
													<Typography component="span" variant="body2" style={{ display: 'block', fontWeight: 500 }}>
														{pageConfiguration.markup.formBlock.codeOfConductAgreementLabel}
													</Typography>
												}
											/>
											<FormHelperText>
												{form.touched.confirmCodeOfConductAgreement && form.errors.confirmCodeOfConductAgreement}
											</FormHelperText>
										</FormControl>
									}
									<Box pb={3} />
									{
										{
											[UserDataAccessStatuses.HasAccess]:
												<Box>
													<Box pb={2} />
													<Typography component="h2" variant="h4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
														{pageConfiguration.markup.hasAccess.header}
													</Typography>
													<Box
														className={classes.infoBlock}
														dangerouslySetInnerHTML={{ __html: pageConfiguration.markup.hasAccess.descriptionHtml }}
													/>
												</Box>,
											[UserDataAccessStatuses.Requested]:
												<Box>
													<Box pb={2} />
													<Typography component="h2" variant="h4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
														{pageConfiguration.markup.requested.header}
													</Typography>
													<Box
														className={classes.infoBlock}
														dangerouslySetInnerHTML={{ __html: pageConfiguration.markup.requested.descriptionHtml }}
													/>
												</Box>,
											[UserDataAccessStatuses.Rejected]:
												<Box>
													<Box pb={2} />
													<Typography component="h2" variant="h4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
														{pageConfiguration.markup.rejected.header}
													</Typography>
													<Box
														className={classes.infoBlock}
														dangerouslySetInnerHTML={{ __html: pageConfiguration.markup.rejected.descriptionHtml }}
													/>
												</Box>,
											[UserDataAccessStatuses.NoAccess]:
												<Box maxWidth="690px" color="#000000">
													<Typography component="h2" variant="h4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
														{pageConfiguration.markup.formBlock.header}
													</Typography>
													<Box pb={2} />
													<Typography component="p" variant="body1">
														{pageConfiguration.markup.formBlock.description}
													</Typography>
													<Box pb={4} />
													<DuaFormFields
														formFieldsConfiguration={pageConfiguration.markup.formBlock.formFields}
														form={form}
													/>
													<Box pb={4} />
													<BusinessOfficialFields
														markupConfiguration={pageConfiguration.markup.formBlock.formFields}
														form = {form}
													/>
													<Box pb={4} />
													<CollaboratorsFields
														markupConfiguration = {pageConfiguration.markup.formBlock.formFields}
														form = {form} />
													<Box pb={4.5} />
													<Typography component="h3" variant="subtitle1" style={{ fontSize: '1.5rem' }}>
														{pageConfiguration.markup.formBlock.supportedDownloads.header}
													</Typography>
													<Box pb={2} />
													<Typography component="p" variant="body1">
														{pageConfiguration.markup.formBlock.supportedDownloads.description}
													</Typography>
													<Box pb={4} />
													<Box display="flex" flexWrap="wrap" justifyContent="space-between">
														<OmicCardsInfo
															omicsConfiguration={pageConfiguration.markup.formBlock.supportedDownloads.omics}
														>
															{({ formOmicCards }) => formOmicCards.map((cardInfo, cardIndex) =>
																<OmicCard
																	key={cardIndex}
																	cardName={cardInfo.name}
																	levels={cardInfo.levels.map(level => ({
																		...level,
																		checkboxValue: Boolean(form.values[level.formFieldName])
																	}))}
																	onLevelClick={onOmicCardLevelClick}
																	wrapperBoxProps={{
																		minWidth: 321,
																		mb: 3,
																		mr: 3
																	}}
																/>
															)}
														</OmicCardsInfo>
													</Box>
													<Box pb={2.5} />
													<Box
														className={classes.infoBlock}
														dangerouslySetInnerHTML={{ __html: pageConfiguration.markup.formBlock.fileInfoSectionHtml }}
													/>
													<Box pb={5} />
													<Box
														className={classes.infoBlock}
														style={{ fontSize: '0.875rem', lineHeight: '1.25rem', letterSpacing: '0.015625rem' }}
														dangerouslySetInnerHTML={{ __html: pageConfiguration.markup.formBlock.agreementsSectionHtml }}
													/>
													<Box color={grey['800']}>
														<FormControl error={form.touched.confirmAgreements && Boolean(form.errors.confirmAgreements)}>
															<FormControlLabel
																classes={{ root: classes.agreementControlRoot, label: classes.agreementControlLabel }}
																control={
																	<Checkbox
																		name="confirmAgreements"
																		checked={form.values.confirmAgreements}
																		onChange={form.handleChange}
																		color="primary"
																	/>
																}
																label={
																	<Typography component="span" variant="body2" style={{ display: 'block', fontWeight: 500 }}>
																		{pageConfiguration.markup.formBlock.agreementsLabel}
																	</Typography>
																}
															/>
															<FormHelperText>
																{form.touched.confirmAgreements && form.errors.confirmAgreements}
															</FormHelperText>
														</FormControl>
													</Box>
													<Box pb={5} />
													<Box display={'flex'} className={classes.submitGroup}>
														<Box className={classes.submitButtonWrapper}>
															<LoaderButton
																isLoading={isRequestLoading}
																wrapperBoxProps={{
																	style: { display: 'inline-block' }
																}}
																ButtonProps={{
																	disabled: isRequestSent,
																	variant: 'contained',
																	color: 'primary',
																	size: 'large',
																	onClick: () => form.handleSubmit()
																}}
															>
																{pageConfiguration.markup.formBlock.submitButtonText}
															</LoaderButton>
														</Box>
														<Box className={classes.validationSummary} pl={2}>
															{ isValidationSummaryVisible &&
																<Typography component="span"
																	variant="body2">
																	{pageConfiguration.markup.errors.summary}
																</Typography>
															}
														</Box>
													</Box>
												</Box>
										}[userAccessStatus]
									}
								</Box>
								<Box
									className={classes.infoBlock}
									style={{ flex: '0 1 690px', height: '100%', alignSelf: 'flex-end' }}>
									<Box dangerouslySetInnerHTML={{ __html: pageConfiguration.markup.rightInfoSectionHtml }} />
									<Box pb={2} />
									<FlowDiagram flowDiagramHtml={pageConfiguration.markup.flowDiagramHtml} />
								</Box>
							</Box>
						</FormikProvider>
					</Container>
					<Box pb={4} />
					<Footer links={pageConfiguration.footer.links} />
				</>
			}
		</Box>
	);
};
